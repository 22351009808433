<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/conveyor/connecting_conveyor">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Conveyor/CONNECTING CONVEYOR.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                CONNECTING CONVEYOR
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/conveyor/inspection_conveyor">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Conveyor/INSPECTION CONVEYOR.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                INSPECTION CONVEYOR
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/conveyor/pcb_claning_conveyor">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Conveyor/PCB CLEANING CONVEYOR.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                PCB CLEANING CONVEYOR
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
           to="/production_equipement/board_handling_system/conveyor/gateway_conveyor">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Conveyor/GATEWAY(TELESCOPIC) CONVEYOR.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                GATEWAY(TELESCOPIC) CONVEYOR
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/conveyor/shuttle_conveyor">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Conveyor/SHUTTLE CONVEYOR.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                SHUTTLE CONVEYOR
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        
       
       
      </div>

    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../../components/Header.vue";

import Footer from "../../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>